import React from "react";
import "./Footer.css";
import { FaFacebookF, FaInstagram, FaTwitter } from "react-icons/fa";
import { Link } from "react-router-dom";

function Footer() {
  return (
    <div className="Footer">
      <div className="footer-abtUs">
        <ul>
          <li className="footer-point-headings"> ABOUT US</li>

          <li className="footer-list-items">
            We blend deep technical knowledge with a commitment to excellence,
            crafting solutions that are flexible, scalable, and aligned with
            your strategic goals. Trust DVRIT to transform your vision into
            impactful results with precision and efficiency.{" "}
            <Link to="/About" className="link-footer">
              Read More{" "}
            </Link>
          </li>
          {/* <li className="icons">
             <div className="social-icons">
              <a href="insta">
                <FaInstagram />
              </a>
            </div>
            <div className="social-icons">
              <a href="facebook">
                <FaFacebookF />
              </a>
            </div>
            <div className="social-icons">
              <a href="twittw">
                <FaTwitter />
              </a>
            </div> 
          </li> */}
        </ul>
      </div>
      <div className="footer-srvcs">
        <ul className="list-margin">
          <li className="footer-point-headings"> SERVICES</li>
          <li className="footer-list-items">
            <Link className="hover-effect-links" to="/CloudSolutions">
              Cloud Solutions
            </Link>
          </li>
          <li className="footer-list-items">
            <Link className="hover-effect-links" to="/Cyberscurity">
              Cybersecurity
            </Link>
          </li>
          <li className="footer-list-items">
            <Link className="hover-effect-links" to="/DigitalTransformation">
              Digital Transformation
            </Link>
          </li>
          <li className="footer-list-items">
            <Link className="hover-effect-links" to="/DataAnylitics">
              Data Analytics
            </Link>
          </li>
          <li className="footer-list-items">
            <Link
              className="hover-effect-links"
              to="/CustomSoftwareDevelopment"
            >
              Custom Software Development
            </Link>
          </li>
        </ul>
      </div>
      <div className="footer-cntctUs">
        <ul>
          <li className="footer-point-headings"> CONTACT US</li>
          <li className="footer-list-items"> 16 Commerce Square </li>
          <li className="footer-list-items">Lace Market </li>
          <li className="footer-list-items">Nottingham NG1 1HS</li>
          <li className="footer-list-items">
            <a className="hover-effect-links" href="tel:+44 7555 995630">
              +44 7555 995630
            </a>
          </li>
          <li className="footer-list-items">
            <a
              className="hover-effect-links"
              href="mailto:info@dvrittech.co.uk"
            >
              info@dvrittech.co.uk
            </a>
          </li>
        </ul>
      </div>
      <div className="copyright">
        <Link className="link-footer" to="/">
          dvrittech.co.uk
        </Link>{" "}
        DVRIT Technology Copyright ©2024 All rights reserved. Powered by{" "}
        <a className="link-footer" target="_blank" href="https://Clotatech.com">
          Clotatech.com
        </a>
      </div>
    </div>
  );
}

export default Footer;
