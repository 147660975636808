import React, { useEffect, useRef, useState } from "react";
import MyIcon from "../../images/Home/DVRITTechLogoNoBG.png";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { NavLink, useLocation } from "react-router-dom";
import { FaCloudMeatball, FaLaptopCode } from "react-icons/fa";
import { BsFillShieldLockFill } from "react-icons/bs";
import { IoMdAnalytics, IoMdClose } from "react-icons/io";
import { GrApps } from "react-icons/gr";
import { RxHamburgerMenu } from "react-icons/rx";
import { MdAddCall } from "react-icons/md";
function Navbar() {
  const dropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState("Select an option");
  const [navOpen, setNavOpen] = useState(false);
  
  const openDropdown = (e) => {
    setIsOpen(true);
  };
  const closeDropdown = (e) => {
    setIsOpen(false);
  };

  const handleOptionClick = (option) => {
    setSelectedOption(option);
    closeDropdown();
  };
  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      closeDropdown();
    }
  };

  useEffect(() => {
    // Attach the event listener when the component mounts

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Clean up the event listener when the component unmounts
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  const location = useLocation();
  const currentPath = location.pathname;
  const pathToMatch = "/Contact";
  const shouldAddClass = currentPath === pathToMatch;
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  function handleClick()
  {
    setNavOpen(!navOpen)
  }

  return (
    <nav>
      <Link to="/">
        <img className="my-icon" src={MyIcon} alt="" />
      </Link>
      <ul ref={dropdownRef} className={navOpen?"openNav":""}>
        <li className="nav-items">
          <NavLink activeClassName="active" exact to="/">
            Home
          </NavLink>
        </li>
        <li className="dropdownparent"
        
        >
          <div
            id="services"
            style={{ cursor: "pointer" }}
            onMouseEnter={openDropdown}
            onMouseLeave={closeDropdown}
          >
            Services ▼
          </div>
          {isOpen && (
            <div
              className="dropdown-content"
              onMouseEnter={openDropdown}
              onMouseLeave={closeDropdown}
            >
              <Link
                to="/CloudSolutions"
                className="dd-links"
                onClick={() => handleOptionClick("Option 1")}
              >
                <div className="icon-div">
                  <FaCloudMeatball />
                </div>
                Cloud Solutions
              </Link>
              <Link
                to="/Cyberscurity"
                className="dd-links"
                onClick={() => handleOptionClick("Option 2")}
              >
                <div className="icon-div">
                  <BsFillShieldLockFill />
                </div>
                Cybersecurity
              </Link>
              <Link
                to="/DigitalTransformation"
                className="dd-links"
                onClick={() => handleOptionClick("Option 3")}
              >
                <div className="icon-div">
                  <FaLaptopCode />
                </div>
                Digital Transformation
              </Link>
              <Link
                to="/DataAnylitics"
                className="dd-links"
                onClick={() => handleOptionClick("Option 4")}
              >
                <div className="icon-div">
                  <IoMdAnalytics />
                </div>
                Data Analytics
              </Link>
              <Link
                to="/CustomSoftwareDevelopment"
                className="dd-links"
                onClick={() => handleOptionClick("Option 4")}
              >
                <div className="icon-div">
                  <GrApps />
                </div>
                Custom Software Development
              </Link>
            </div>
          )}
        </li>
        <li className="nav-items">
          <NavLink to="/About">About Us</NavLink>
        </li>
        <li className="nav-items">
          <NavLink to="/Career">Careers</NavLink>{" "}
        </li>
      </ul>
      <div className="contact-section">
        <NavLink
          to="/Contact"
          className={`btn ${shouldAddClass ? "border-right" : ""}`}
        >
          <div className="contact-icon">
          <MdAddCall />
          </div>
          <div className="contact-text">
            Contact
          </div>
        </NavLink>
      </div>
     <div className="hamburger" onClick={handleClick}>
{navOpen?<IoMdClose  />:<RxHamburgerMenu  />}
     </div>
    </nav>
  );
}

export default Navbar;
