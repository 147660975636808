import React from "react";
import "./About.css";
import Navbar from "../Comman Pages/Navbar/Navbar";
import banner from "../images/aboutus.png";
import { HiOutlineRocketLaunch } from "react-icons/hi2";
import { FaRegEye } from "react-icons/fa";
import { RxValue } from "react-icons/rx";
import { GiConfirmed } from "react-icons/gi";
import { RiContactsLine } from "react-icons/ri";

import mission from "../images/careers/Mission.png";
import vission from "../images/careers/Vision.png";
import Values from "../images/careers/Values.png";
import Intigrity from "../images/careers/Integrity.png";
import Innovation from "../images/careers/Innovation.png";
function About() {
  return (
    <div className="About-Hero">
      <Navbar />
      <div className="About-container">
        <div className="about-left">
          <h1>About Us</h1>
          <p>
            We create the unbelievable, we befriend corporate challenges, we
            originate ideas, and we foresee the future.
          </p>
        </div>
        <div className="about-right">
          <img src={banner} alt="" />
        </div>
        <div className="about-bottom">
          <h2>Overview</h2>
          <p>
            <div className="abt-txt-hilit"> We Create The Unbelievable </div>
            we befriend corporate challenges, we originate ideas, and we foresee
            the future. <span className="company-name">DVRIT Techo </span>
          </p>
          <p>
            <span className="company-name">DVRIT Techo </span> is an
            organization that brings together some of the brightest minds in the
            world. Its motto is "inventing advanced technologies & innovating
            them further to invent more." We design intelligent BPO and IT
            solutions that comprehend, satisfy, and exceed our clientele's
            business needs. We provide the dynamic business world with services
            like Cyber Security, Cloud Solutions, Data Analytics, Digital
            Transformation, Custom Software Development, and more by fusing our
            years of experience with aspirational plans.
          </p>
        </div>
        <div className="Mission">
          <div className="mission-icon">
            <img src={mission} alt="" />
          </div>
          <div className="mission-text">
            <h2 className="mission-subheading">Mission</h2>
            We recruit and develop professionals to become leading digital
            innovators by promoting a culture of collaboration, openness, and
            lifelong learning in order to offer excellent software services
            internationally.
          </div>

          <div className="mission-text">
            <h2 className="mission-subheading">Vision</h2>
            To be a globally renowned company that uses technology to deliver
            cutting-edge solutions from world-class professionals.
          </div>
          <div className="mission-icon">
            <img src={vission} alt="" />
          </div>
          <div className="mission-icon">
            <img src={Values} alt="" />
          </div>
          <div className="mission-text">
            <h2 className="mission-subheading">Values</h2>
            Our core values guide our actions and decisions, ensuring we
            maintain the highest standards of integrity, excellence, and
            innovation in all we do.
          </div>
          <div className="mission-text">
            <h2 className="mission-subheading">Integrity</h2>
            We believe in transparent and ethical practices, ensuring honesty,
            accountability, trust, credibility, fairness, responsibility, and
            moral conduct in all our actions and business dealings.
          </div>
          <div className="mission-icon">
            <img src={Intigrity} alt="" />
          </div>
          <div className="mission-icon">
            <img src={Innovation} alt="" />
          </div>
          <div className="mission-text">
            <h2 className="mission-subheading">Innovation</h2>
            Always exploring new technologies and methodologies, fostering
            creativity, and driving forward-thinking solutions.
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
