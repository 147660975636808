import React from "react";
import "./DigitalTransformation.css";
import banner from "../../images/services Images/DigitalTransformation.png";
import Navbar from "../../Comman Pages/Navbar/Navbar";
import { IoIosApps } from "react-icons/io";
import help from "../../images/how we can help.png";
import { GrTask } from "react-icons/gr";
import { FaRegChartBar, FaTools } from "react-icons/fa";
import { PiStrategyFill } from "react-icons/pi";

function DigitalTransformation() {
  return (
    <div className="DT-Hero">
      <Navbar />
      <div className="dd-container">
        <div className="left-content">
          <h1>Digital Transformation</h1>
          <p>
            Transform your business with our digital solutions, streamlining
            processes and enhancing customer experiences. We integrate
            cutting-edge technologies to modernize operations and boost agility.
          </p>
        </div>
        <div className="right-content">
          <img src={banner} alt="" />
        </div>
        <div className="bottom-content">
          <h2>Leading Your Digital Revolution </h2>
          <p>
            We understand that conducting business in the rapidly evolving world
            of technology can present challenges for companies of all sizes.
            With the aid of our digital transformation services, you can fully
            leverage digital technology to spur business growth, boost
            productivity, and maintain an advantage over competitors.
          </p>
          <p>
            Our team of professionals will collaborate with you to understand
            your unique business requirements and objectives before creating a
            customized plan to help you achieve them. We offer the knowledge and
            experience to help you enhance your company and increase your
            success, from process automation and data analytics to cloud
            computing and digital consumer interaction.
          </p>
        </div>
        <div className="card-section">
          <h2>Digital Transformation Services</h2>
          <p className="para">
            DVRIT Digital Transformation consulting firm has made a significant
            impact on the UK market.
          </p>
          <div className="card-grid">
            <div className="srvcs-cards" id="card1">
              <GrTask className="card-icon" />
              <h4>Modernization of Applications </h4>
              <p>
                The most important "demand of the day" is application
                modernization due to the increasing demand for sophisticated
                programs to satisfy the constantly evolving requirements.{" "}
              </p>
            </div>
            <div className="srvcs-cards" id="card2">
              <FaRegChartBar className="card-icon" />
              <h4>Restructuring Businesses </h4>
              <p>
                We help businesses rethink their operations and business
                procedures so they may better utilize digital technology and
                increase productivity.
              </p>
            </div>
            <div className="srvcs-cards" id="card3">
              <FaTools className="card-icon" />
              <h4>Utilizing Digital Tools </h4>
              <p>
                With a focus on their unique requirements and objectives, we
                assist companies in creating and implementing bespoke digital
                applications.
              </p>
            </div>
            <div className="srvcs-cards" id="card4">
              <PiStrategyFill className="card-icon" />
              <h4>Consulting for Digital Strategy </h4>
              <p>
                Our team of professionals collaborates with businesses to create
                a comprehensive digital transformation plan that aligns with
                their goals and objectives.
              </p>
            </div>
          </div>
        </div>
        <div className="help-section">
          <div className="help-left">
            <img src={help} alt="helping img" />
          </div>
          <div className="help-right">
            <h4>How Do We Help?</h4>
            <p>
              Our digital transformation services help businesses stay ahead of
              the rapidly evolving digital landscape. We provide specialized
              solutions that promote innovation, streamline workflows, and
              improve user experiences. Collaborate with our professionals to
              unleash growth and embark on a path of transformation. Gain
              insights from assessments, use cutting-edge technology to craft a
              customized digital transformation plan, and leverage digital
              transformation solutions to open doors for development. Together
              with our knowledgeable team, optimize processes, enhance
              experiences, and embark on a transformative journey.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DigitalTransformation;
