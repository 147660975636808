import React from "react";
import Navbar from "../../Comman Pages/Navbar/Navbar";
import "./CloudSolutions.css";
import banner from "../../images/Blue Cloud Flat Technology Free Logo.png";
import img1 from "../../images/cloud/High Performance.svg";
import img2 from "../../images/cloud/Security final.svg";
import img3 from "../../images/cloud/cost effective.png";
import img4 from "../../images/cloud/scalability.svg";
import img5 from "../../images/cloud/Cloud Migration.png";
import img6 from "../../images/cloud/Cloud managing.png";
import img7 from "../../images/cloud/Cloud Hosting.png";
import img8 from "../../images/cloud/Cloud Security.png";
import { SiTicktick } from "react-icons/si";

function CloudSolutions() {
  return (
    <div className="CloudSocutions-hero">
      <Navbar />
      <div className="cloud-grid-container">
        <div className="C-grid-left">
          <h1 className="heading-cloud-services">Cloud Services</h1>
          <p className="cloud-srvcs-para">
            Cloud computing is becoming an increasingly important tool for
            fostering innovation and satisfying the constantly shifting
            requirements of people on a global scale.{" "}
          </p>
        </div>
        <div className="C-grid-right">
          <img src={banner} alt="" />
        </div>
        <div className="C-grid-bottom">
          <div className="imp-cloud-srvs">
            <h2 className="cloud-sub-headings">
              Importance of Cloud Services{" "}
            </h2>
            <p>
              Cloud services have revolutionized the way businesses and
              individuals manage and utilize data and applications. Here are
              some key reasons why cloud services are crucial{" "}
            </p>
            <ul className="imp-cloud-srvs-list">
              <SiTicktick />

              <li>
                Cloud services offer scalability and flexibility, allowing users
                to adjust their resources based on demand. This means businesses
                can efficiently handle varying workloads without investing
                heavily in physical infrastructure.
              </li>

              <SiTicktick />
              <li>
                Cost efficiency is another significant advantage. By using cloud
                services, companies can avoid the high costs of purchasing and
                maintaining hardware. The cloud service ensures that you only
                pay for what you use, resulting in substantial savings.
              </li>

              <SiTicktick />
              <li>
                With cloud services, accessibility and collaboration are greatly
                enhanced. Data and applications can be accessed from anywhere
                with an internet connection, facilitating remote work and
                allowing team members to collaborate seamlessly from different
                locations.
              </li>

              <SiTicktick />
              <li>
                Security is a top priority for cloud providers, who invest in
                advanced security measures such as encryption, identity
                management, and regular updates to protect sensitive data from
                cyber threats.{" "}
              </li>
            </ul>
          </div>
        </div>
        <div className="CS-section">
          <h4>Benefits of Cloud Services </h4>
          <p className="para">
            Numerous advantages are available to your company from our cloud
            management services, including{" "}
          </p>
          <div className="CS-section-srvcs">
            <div className="CS-card" id="CS-card1">
              <div>
                <img src={img4} alt="" />
              </div>
              <h5>The ability to scale </h5>
              <p>
                Because of the great scalability of our cloud solutions, your
                company may quickly grow or shrink as needed.{" "}
              </p>
            </div>
            <div className="CS-card" id="CS-card2">
              <div>
                <img src={img2} alt="" />
              </div>

              <h5>Safety </h5>
              <p>
                Our cloud solutions are extremely safe, guaranteeing that your
                apps and data are always safe.{" "}
              </p>
            </div>
            <div className="CS-card" id="CS-card3">
              <div>
                <img src={img3} alt="" />
              </div>
              <h5>Elevated Efficiency </h5>
              <p>
                Our cloud solutions are incredibly efficient, so your
                applications will function swiftly and without interruptions.
              </p>
            </div>
            <div className="CS-card" id="CS-card4">
              <img src={img1} alt="" />
              <h5>Economical </h5>
              <p>
                Our cloud solutions are incredibly efficient, so your apps will
                function swiftly and without hiccups.{" "}
              </p>
            </div>
          </div>
        </div>
        <div className="CS-Section-Offrings">
          <h4>Cloud Services Offerings </h4>
          <p className="para">
            Our cloud solutions are incredibly efficient, so your apps will
            function swiftly and without hiccups.{" "}
          </p>
          <div className="CS-offring">
            <div className="CS-Card-Offering" id="offringCard1">
              <div>
                <img src={img5} alt="" />
              </div>
              <h5>Cloud migration </h5>
              <p>
                Your business operations will be minimally disrupted while we
                assist you in moving your software applications to the cloud. We
                guarantee a smooth transfer with our experts.{" "}
              </p>
            </div>
            <div className="CS-Card-Offering" id="offringCard2">
              <div>
                <img src={img6} alt="" />
              </div>
              <h5>Management </h5>
              <p>
                We provide cloud monitoring and assistance around-the-clock to
                make sure your apps function properly. Our experts take
                proactive measures to resolve problems before they get worse.{" "}
              </p>
            </div>
            <div className="CS-Card-Offering" id="offringCard3">
              <div>
                <img src={img7} alt="" />
              </div>
              <h5>Hosting </h5>
              <p>
                Our 24/7 cloud monitoring and support services guarantee optimal
                application performance by addressing issues before they become
                serious.
              </p>
            </div>
            <div className="CS-Card-Offering" id="offringCard4">
              <div>
                <img src={img8} alt="" />
              </div>
              <h5>Security </h5>
              <p>
                We ensure your data is secure and give you
                peace of mind in a complex cyber environment. Our cutting-edge
                security solutions offer unparalleled protection against
                constantly emerging threats.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CloudSolutions;
