import React from "react";
import "./Home.css";
import banner from "../images/Home-banner.png";
import Navbar from "../Comman Pages/Navbar/Navbar";
import { SiTicktick } from "react-icons/si";
import { FaCloudMeatball, FaLaptopCode } from "react-icons/fa";
import { IoMdAnalytics } from "react-icons/io";
import { BsFillShieldLockFill } from "react-icons/bs";
import { GrApps } from "react-icons/gr";
import { Button } from "primereact/button";
import { Link } from "react-router-dom";

function Home() {
  return (
    <main className="Hero">
      <Navbar />
      <div className="Home-container">
        <div className="Home-left">
          <h1>Grow Your Business With Our Help</h1>
          <p>
            Trust DVRIT to transform your vision into impactful results with
            precision and efficiency.
          </p>
        </div>
        <div className="Home-right">
          <img src={banner} alt="Home banner" />
        </div>
        <div className="Home-bottom">
          <h2>Why Choose DVRIT?</h2>
          <div className="home-bottom-left">
            <p>
              At DVRIT, we are distinguished by our dedication to providing
              customized solutions that precisely match your individual
              requirements. With a wealth of expertise and state-of-the-art
              knowledge, our team of professionals ensures that every project
              receives creative and practical solutions.
            </p>
            <p>
              Using the latest technology, we boost productivity and scalability
              while ensuring top quality and client satisfaction. With a proven
              track record, DVRIT is your reliable partner for achieving
              business goals and navigating the digital landscape.
            </p>
          </div>
          <div className="home-bottom-right">
            <ul className="why-dvrit-points">
              <SiTicktick />
              <li>Tailored software for web and mobile. </li>
              <SiTicktick />
              <li>Complete management from design to maintenance. </li>
              <SiTicktick />
              <li>Skilled professionals handling all phases</li>
              <SiTicktick />
              <li>Continuous improvement through iterative releases. </li>
              <SiTicktick />
              <li>Rigorous testing for reliable performance.</li>
            </ul>
          </div>
        </div>
        <div className="wht-we-do">
          <div className="wht-we-do-left">
            <h3>What We Do</h3>
            <hr />
            <p>
              At DVRIT, we are your reliable partner for bespoke software
              solutions that drive your business forward. With years of industry
              expertise, our skilled team delivers innovative, tailored software
              designed to meet your specific needs and objectives.
            </p>
          </div>
          <div className="wht-we-do-right">
            <div className="home-cards" id="home-card-1">
              <div className="home-card-icon">
                <FaCloudMeatball />
              </div>
              <div className="home-card-content">
                <h5>Cloud Services</h5>
                <p>
                  Cloud solutions that are safe and scalable may revolutionize
                  your company and guarantee improved productivity and smooth
                  operations. Our knowledgeable staff can help you maximize your
                  IT infrastructure and spur corporate expansion with tailored
                  cloud plans and ongoing support.
                </p>
                <p className="read-more">
                  <Link to="/CloudSolutions">Read More</Link>
                </p>
              </div>
            </div>
            <div className="home-cards" id="home-card-2">
              <div className="home-card-icon">
                <GrApps />
              </div>
              <div className="home-card-content">
                <h5>Custom Softwere Development</h5>
                <p>
                  specialized software programs that are made to match your
                  particular business requirements and promote creativity and
                  efficiency.
                </p>
                <p className="read-more">
                  <Link to="/CustomSoftwareDevelopment">Read More</Link>
                </p>
              </div>
            </div>
            <div className="home-cards" id="home-card-3">
              <div className="home-card-icon">
                <IoMdAnalytics />
              </div>
              <div className="home-card-content">
                <h5>Data Anylitics</h5>
                <p>
                  Utilize our cutting-edge analytics services to unleash the
                  potential of data and gain practical insights for wise
                  decision-making. You may use big data to find patterns, make
                  better decisions, and strengthen strategic planning with the
                  aid of our all-inclusive analytics solutions.
                </p>
                <p className="read-more">
                  <Link to="/DataAnylitics">Read More</Link>
                </p>
              </div>
            </div>
            <div className="home-cards" id="home-card-4">
              <div className="home-card-icon">
                <BsFillShieldLockFill />
              </div>
              <div className="home-card-content">
                <h5>Cyber Security</h5>
                <p>
                  With our all-inclusive cyber security solutions, you can
                  safeguard your digital assets and shield your company from
                  ever-changing threats. To guarantee the protection of your
                  sensitive data and the continuity of DVRIT Tech company
                  activities, we offer multi-layered security solutions and
                  proactive risk management.
                </p>
                <p className="read-more">
                  <Link to="/Cyberscurity">Read More</Link>
                </p>
              </div>
            </div>
            <div className="home-cards" id="home-card-5">
              <div className="home-card-icon">
                <FaLaptopCode />
              </div>
              <div className="home-card-content">
                <h5>Digital Transformation</h5>
                <p>
                  Transform your business with our digital solutions,
                  streamlining processes and enhancing customer experiences. We
                  integrate cutting-edge technologies to modernize operations
                  and boost agility. Partner with us for a seamless transition
                  to a digital-first enterprise.
                </p>
                <p className="read-more">
                  <Link to="/DigitalTransformation">Read More</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="like-wht-u-see-parent">
          <div className="like-wht-u-see">
            <div className="wht-u-see-left">
              <h6>Like What you see</h6>
              <p>
                Trust DVRIT to transform your vision into impactful results with
                precision and efficiency.
              </p>
            </div>
            <Link to="/Contact" className="btn">
              Contact
            </Link>
          </div>
        </div>
      </div>
    </main>
  );
}

export default Home;
