import React from "react";
import "./Career.css";
import Navbar from "../Comman Pages/Navbar/Navbar";
import banner from "../images/careers.png";
import { CiTrophy } from "react-icons/ci";
import { GiThreeFriends } from "react-icons/gi";
import { MdDiversity2, MdOutlineBalance } from "react-icons/md";
import { IoPeopleOutline } from "react-icons/io5";

function Career() {
  return (
    <div className="Career-Hero">
      <Navbar />
      <div className="career-container">
        <div className="career-left">
          <h1>Careers</h1>
          <p>
            Find your calling and set out on a path of personal development.
            Come along and let's collectively create an impact.{" "}
          </p>
        </div>
        <div className="career-right">
          <img src={banner} alt="" />
        </div>
        <div className="career-bottom">
          <h2>Discover careers with impact at DVRIT </h2>
          <p>
            Join a firm where your knowledge is put to use. We're redefining
            software-defined for businesses across all sectors. Consider
            yourself in the front of the digital revolution, contributing on all
            fronts.{" "}
          </p>
        </div>
        <div className="career-card-section">
          <h3>Explore an exceptional workplace </h3>
          <p className="">
            Study up on our customs and what it means to be a part of the DVRIT
            community.{" "}
          </p>
          <div className="career-cards" id="careerCared1">
            <div className="career-icon">
              <CiTrophy />
            </div>
            <h6>Appreciation and assessment </h6>
            <p>
              Receive praise for your efforts and direction on how to develop in
              our feedback-rich environment.{" "}
            </p>
          </div>
          <div className="career-cards" id="careerCared2">
            <div className="career-icon">
              <GiThreeFriends />
            </div>
            <h6>amiable setting </h6>
            <p>
              Enjoy working in a multicultural team on a work environment where
              you are respected.{" "}
            </p>
          </div>
          <div className="career-cards" id="careerCared3">
            <div className="career-icon">
              <MdOutlineBalance />
            </div>
            <h6>Balance between work and life </h6>
            <p>
              Provide for your "life" time by establishing a flexible schedule.{" "}
            </p>
          </div>
          <div className="career-cards" id="careerCared4">
            <div className="career-icon">
              <MdDiversity2 />
            </div>
            <h6>Inclusion and Diversity</h6>
            <p>
              In our welcoming environment, where you will collaborate with
              peers to advance and be successful with diversity and inclusion
              initiatives, you will feel at home.{" "}
            </p>
          </div>
          <div className="career-cards" id="careerCared5">
            <div className="career-icon">
              <IoPeopleOutline />
            </div>
            <h6>Achieve more with us </h6>
            <p>
              In the correct workplace, contented workers flourish. Find out how
              Luxoft makes a happy place to work from anywhere
            </p>
          </div>
        </div>
        <div className="career-info">
          <div className="career-info-content">
            <h4>Careers</h4>
            <p>
              Careers If you're looking for a role where you can grow, we want
              to hear from you. Drop us an email at{" "}
              <a className="company-name" href="mailto:info@dvrittech.co.uk">
                info@dvrittech.co.uk
              </a>{" "}
              and our team will get back in touch with you.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Career;
