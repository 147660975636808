import React from "react";
import "./CostomSoftwareDev.css";
import Navbar from "../../Comman Pages/Navbar/Navbar.jsx";
import CustomSoftware from "../../images/services Images/Custom Software Development .png";
import { SiTicktick } from "react-icons/si";
import mobile from "../../images/services Images/Mobile development.png";
import api from "../../images/services Images/API And Software 5.png";
import fullstack from "../../images/services Images/Full Stack.png";
import consult from "../../images/services Images/Software Consulatancy.png";

function CostomSoftwareDev() {
  return (
    <div className="CSD-Hero">
      <Navbar />
      <div className="csd-container">
        <div className="csd-left">
          <h1> Custom Software Development</h1>
          <p>
            specialized software programs that are made to match your particular
            business requirements and promote creativity and efficiency.
          </p>
        </div>
        <div className="csd-right">
          <img src={CustomSoftware} alt="" />
        </div>
        <div className="csd-bottom">
          <h2> Why DVRIT for Custom Software Development Services? </h2>
          <div className="csd-bottom-left">
            <p>
              Requirements gathering, ideation, system architecture supporting
              iterative releases, building clean and testable code, and a
              quality testing process are all elements in our software
              development cycle.
            </p>
            <p>
              The requirements collecting, ideation, system architecture
              supporting iterative releases, building clean and testable code,
              and quality testing procedure are all elements in our software
              development cycle.{" "}
            </p>
          </div>
          <div className="csd-bottom-right">
            <div className="csd-right-icon">
              <SiTicktick />
            </div>
            <div className="csd-right-point">
              total ownership of the product{" "}
            </div>
            <div className="csd-right-icon">
              <SiTicktick />
            </div>
            <div className="csd-right-point">Software quality assured </div>
            <div className="csd-right-icon">
              <SiTicktick />
            </div>
            <div className="csd-right-point">
              The freedom to use your personalized software{" "}
            </div>
            <div className="csd-right-icon">
              <SiTicktick />
            </div>
            <div className="csd-right-point">sophisticated tech stacks </div>
            <div className="csd-right-icon">
              <SiTicktick />
            </div>
            <div className="csd-right-point">
              open lines of communication and reporting{" "}
            </div>
            <div className="csd-right-icon">
              <SiTicktick />
            </div>
            <div className="csd-right-point">
              Assurance of post-purchase assistance{" "}
            </div>
          </div>
        </div>
        <div className="csd-bottom">
          <h2>Custom Software Development Offers </h2>
          <div className="csd-bottom-image">
            <img src={mobile} alt="" className="img" />
          </div>
          <div className="csd-bottom-paragraph">
            <h3>Mobile development </h3>
            <p>
              Our developers produce and release mobile apps that are fast,
              reliable, and efficient. To identify and highlight your
              competitive edge, we thoroughly examine the market and your brand
              identity. Our designers create a visually appealing user interface
              (UI) and a simple, intuitive user experience (UX) to ensure
              conversion, engagement, and easy adoption. We ensure seamless
              integration with back ends and any third-party software, thanks to
              our extensive knowledge of a broad range of APIs. Additionally,
              our developers rejuvenate outdated mobile apps by redesigning the
              architecture and refactoring the code. We offer comprehensive
              services, including security, compliance, performance management,
              and app evolution, along with post-launch L1–L3 support.
            </p>
          </div>
          <div className="csd-bottom-paragraph">
            <h3>Software integration and API development </h3>
            <p>
              Services for developing bespoke APIs assist companies in promoting
              collaboration and data exchange between their own apps and those
              of third parties. We provide secure and thoroughly defined APIs so
              your apps can interact and work together with vendors, clients,
              and partners to increase sales.
            </p>
            <h5>organized project management and oversight </h5>
            <p>
              We place a high value on productive communication between team
              members and stakeholders, establish precise success criteria,
              update stakeholders on project progress, and follow a methodical
              procedure for adopting modifications.{" "}
            </p>
            <h5>Enough records </h5>
            <p>
              For authorized parties, the vital information pertaining to our
              initiatives is always readily available, organized, and current.
              Our project management methodology places a strong emphasis on
              comprehensive documentation that addresses every facet of the
              project as well as efficient knowledge management to gather,
              distribute, and store important insights.
            </p>
          </div>
          <div className="csd-bottom-image soft-intg">
            <img src={api} alt="" className="img" />
          </div>
          <div className="csd-bottom-image">
            <img src={fullstack} alt="" className="img" />
          </div>
          <div className="csd-bottom-paragraph">
            <h3>Full Stack Development </h3>
            <p>
              Full Stack Development Services facilitate the development of a
              wide range of web-based applications and guarantee a great online
              user experience. Every online solution is customized to meet the
              unique requirements of consumers, business partners, patients,
              interest-based groups, and e-commerce users. Our online portals
              dynamically aggregate data from company systems to give users
              access to up-to-date information and assistance.{" "}
            </p>
            <p>
              DVRIT began as entry-level stores for new businesses and has since
              developed to provide specialized e-commerce solutions for big,
              quickly expanding companies. We employ intuitive page editors to
              improve business presentations and brand development so that
              content maintenance is simple. We increase business productivity
              by utilizing scalable microservices architectures and automated
              business processes.{" "}
            </p>
          </div>
          <div className="csd-bottom-paragraph">
            <h3>Software consulting </h3>
            <p>
              We gather and analyze your business needs, conceptualize software
              to cover them, and help you get the best value from its
              development. Our team starts by conducting a thorough assessment
              of your operational requirements, identifying key areas where
              custom software solutions can drive efficiency and growth. We then
              move to the design phase, where we create detailed software
              concepts tailored specifically to address these needs. Throughout
              the development process, we ensure that the software is optimized
              to deliver maximum value, leveraging the latest technologies and
              best practices. By maintaining close collaboration with you, we
              ensure that the final product not only meets but exceeds your
              expectations, providing robust, scalable, and user-friendly
              solutions that support your long-term business goals.{" "}
            </p>
          </div>
          <div className="csd-bottom-image consult">
            <img src={consult} alt="" className="img" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default CostomSoftwareDev;
