import React from "react";
import "./DataAnylitics.css";
import DataAnalatics from "../../images/services Images/Data Analysis.png";
import Navbar from "../../Comman Pages/Navbar/Navbar";
import card1img from "../../images/DataAnylitics/datamining.png";
import card2img from "../../images/DataAnylitics/img2.png";
import card3img from "../../images/DataAnylitics/img4.png";
import card4img from "../../images/DataAnylitics/img1.png";
function DataAnylitics() {
  return (
    <div className="DA-Hero">
      <Navbar />
      <div className="da-container">
        <div className="da-left">
          <h1> Data Analytics</h1>
          <p>
            Utilize our cutting-edge analytics services to unleash the potential
            of data and gain practical insights for wise decision-making
          </p>
        </div>
        <div className="da-right">
          <img src={DataAnalatics} alt="" />
        </div>
        <div className="da-bottom">
          <h2>
            Use Data Analytics Solutions to Maximize the Potential of Your Data
          </h2>
          <p>
            We believe that each data point is distinct and has a backstory. As
            the industry leader in data analytics services, we're dedicated to
            helping our clients make the most of their data by combining
            advanced analytics, cutting-edge technology, and careful planning.
            Our knowledgeable staff works to maximize data value and transform
            it into insightful knowledge that fosters innovation and corporate
            growth. We have the expertise and resources to help you reach your
            goals, whether you want to increase operational efficiency, enhance
            customer service, or generate new revenue.
          </p>
        </div>
        <div className="why-da-anylitics">
          <h4>Why DVRIT Analytics?</h4>
          <div className="da-cards">
            <div className="da-card da-card1">
              <h5>Personalized Analytics Solutions</h5>
              <p>
                We personalize our data analytics services to each client's
                individual requirements, providing them with valuable insights
                that they can use to improve their business.
              </p>
            </div>
            <div className="da-card da-card2">
              <h5>Commitment to Innovation</h5>
              <p>
                DVRIT is dedicated to staying at the forefront of data analytics
                technology so that we can offer our clients the best data
                analytics services in response to their evolving demands.
              </p>
            </div>
            <div className=" da-card da-card3">
              <h5>Comprehensive Data Management</h5>
              <p>
                We handle every stage of the data management process, from data
                collection and processing to analysis, to provide our clients
                with the most accurate and practical information for critical
                business decision-making.
              </p>
            </div>
            <div className="da-card da-card4">
              <h5>In-depth Industry Knowledge</h5>
              <p>
                Our skilled professionals have in-depth knowledge across many
                sectors, allowing us to accurately address the challenges and
                capture the opportunities unique to each industry.
              </p>
            </div>
          </div>
        </div>
        <div className="da-features">
          <h4>Our Offerings</h4>
          <div className="da-features-card da-card1">
            <img src={card1img} alt="" />
            <h5>Data Mining</h5>
            <p>
              We use sophisticated algorithms to extract insightful information
              from massive datasets in order to find patterns and correlations
              that will aid in decision-making.
            </p>
          </div>
          <div className="da-features-card da-card2">
            <img src={card2img} alt="" />
            <h5>Visualization</h5>
            <p>
              We transform complicated data into graphical representations, such
              as dashboards and charts, to facilitate the rapid understanding
              and interpretation of important findings.
            </p>
          </div>
          <div className="da-features-card da-card3">
            <img src={card3img} alt="" />
            <h5>Data Transformation</h5>
            <p>
              We ensure accuracy and consistency for trustworthy insights by
              cleaning and converting unstructured data into a format that is
              organized and ready for analysis.
            </p>
          </div>
          <div className="da-features-card da-card4">
            <img src={card4img} alt="" />
            <h5> Business intelligence</h5>
            <p>
              We analyze company data using techniques and tools to produce
              insights that can be used to make more informed decisions and run
              operations more efficiently.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DataAnylitics;
