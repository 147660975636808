import React from "react";
import CyberSecurity from "../../images/services Images/Cyber Security Image (1).png";
import "./Cyberscurity.css";
import Navbar from "../../Comman Pages/Navbar/Navbar";
import { TbBrowserCheck } from "react-icons/tb";
import { MdOutlineAutoGraph, MdOutlineSecurity } from "react-icons/md";
import { BsShieldLock } from "react-icons/bs";
import { FaLightbulb } from "react-icons/fa";
import { SiTicktick } from "react-icons/si";
function Cyberscurity() {
  return (
    <div className="Cyber-Hero">
      <Navbar />
      <div className="Cyber-Container">
        <div className="Cyber-left">
          <h1>Cyber Security</h1>
          <p>
            With our all-inclusive cyber security solutions, you can safeguard
            your digital assets and shield your company from ever-changing
            threats.
          </p>
        </div>
        <div className="Cyber-right">
          <img src={CyberSecurity} alt="" />
        </div>
        <div className="Cyber-bottom">
          <h2>What Is CyberSecurity?</h2>
          <p>
            Cybersecurity is an applied discipline focused on safeguarding
            networks that enable electronic communication, as well as
            information systems. Practitioners of cybersecurity, who offer
            engineering, consulting, and IT security services to lower risk for
            governments and corporations, view the field as both an art and a
            science
          </p>
        </div>
        <div className="imp-cyber-security">
          <h2 className="imp-heading">Importance Of Cyber Security </h2>
          <p>
            {" "}
            Cybersecurity is the process of defending computer networks,
            systems, and data from online threats, illegal access, and harm. It
            includes a variety of tools, procedures, and controls designed to
            protect data and ensure the privacy, accuracy, and availability of
            digital assets. Cybersecurity is important for many reasons:
          </p>
          <ul className="importance-cld-srvcs-list">
            <SiTicktick />
            <li>
              <span>Protection of Sensitive Information : </span>
              It defends against identity theft and financial loss by keeping
              private, financial, and personal information protected from theft
              or unauthorized access.
            </li>
            <SiTicktick />
            <li>
              <span>Prevention of Data Breaches : </span>
              Robust cybersecurity protocols aid in preventing data breaches,
              which have the potential to compromise confidential data and harm
              an organization's reputation
              <div>
                Cybersecurity protects against a variety of threats, such as
                ransomware, malware, and phishing attempts, keeping data and
                systems safe.
              </div>
            </li>
            <SiTicktick />
            <li>
              <span>Ensuring Business Continuity : </span>
              In the case of an attack, cybersecurity helps minimize downtime
              and operational interruptions by preserving the availability and
              dependability of systems.
            </li>
            <SiTicktick />
            <li>
              <span>Regulation Adherence : </span>
              It guarantees compliance with legal and regulatory obligations for
              privacy and data protection, averting possible legal
              repercussions.
            </li>
          </ul>
        </div>
        <div className="Cyber-Solutions">
          <h2>Our Solutions</h2>
          <div className="Cyber-cards" id="Cyber-Card1">
            <div className="Cyber-icons">
              <TbBrowserCheck />
            </div>
            <h5>Penetration Testing</h5>
            <p>
              We provide a comprehensive range of penetration testing services
              to address all aspects of your security. We offer post-assessment
              assistance to help you address issues and improve your security
              posture, with customized testing strategies to meet your unique
              security requirements and business objectives.
            </p>
          </div>
          <div className="Cyber-cards" id="Cyber-Card2">
            <div className="Cyber-icons">
              <MdOutlineAutoGraph />
            </div>
            <h5>Cyber Security Consulting</h5>
            <p>
              Our team is committed to safeguarding your company against
              constantly evolving cyber threats. We ensure the protection of
              your data, assets, and reputation by offering a complete range of
              services tailored to meet your specific needs.
            </p>
          </div>
          <div className="Cyber-cards" id="Cyber-Card3">
            <div className="Cyber-icons">
              <MdOutlineSecurity />
            </div>
            <h5>Risk Assesment</h5>
            <p>
              Conducting a thorough risk assessment is not just sensible but
              necessary in this day and age, as cyberattacks become more
              sophisticated. We use a rigorous and systematic approach to risk
              assessment, making sure that every effort is made to secure your
              digital assets.
            </p>
          </div>
          <div className="Cyber-cards" id="Cyber-Card4">
            <div className="Cyber-icons">
              <FaLightbulb />
            </div>
            <h5>Complaince</h5>
            <p>
              Our expertise lies in helping your business navigate this
              intricate process and ensuring that everything is done legally and
              safely. We create comprehensive, clear security policies that
              comply with legal criteria, carry out thorough audits to identify
              compliance gaps, and offer practical suggestions.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Cyberscurity;
