import React, { useRef, useState } from "react";
import { InputText } from "primereact/inputtext";
import "./contactus.css";
import { InputTextarea } from "primereact/inputtextarea";
import { Button } from "primereact/button";
import Navbar from "../Comman Pages/Navbar/Navbar";
import CanvaImg from "../images/Green Gray Handwriting Contact Us Add Phone Chat Instagram Post (1).png";
import { Toast } from "primereact/toast";

function ContactUsPage() {
  const toast = useRef(null);
  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");

  const [loading, setLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    // Send form data to backend
    const response = await fetch("/api/send-email", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullName: fullName,
        email: email,
        subject: subject,
        message: message,
      }),
    });

    if (response.ok) {
      toast.current.show({
        severity: "success",
        summary: "Success",
        detail: "Form submitted successfully.",
        life: 3000,
      });
      setLoading(false);
      setFullName("");
      setEmail("");
      setMessage("");
      setSubject("");
    } else {
      toast.current.show({
        severity: "error",
        summary: "Error",
        detail: "Error submitting form.",
        life: 3000,
      });
    }
    setLoading(false);
  };

  return (
    <div className="Hero-ContactUs">
      <Toast ref={toast} />
      <Navbar />
      <div className="contactUs-card">
        <div className="contact-details">
          <img src={CanvaImg} height={"300px"} width={""} alt="" />
          <ul className="details-list">
            <li className="list-heading">Office Address</li>
            <li className="details-list-items">
              <span className="point-heighlights">Address:</span> 16 Commerce
              Square Lace Market Nottingham NG1 1HS
            </li>
            <li className="details-list-items">
              <span className="point-heighlights">Phone-No:</span>
              <a href="tel:+447555995630"> +44 7555 995630</a>
            </li>
            <li className="details-list-items">
              <span className="point-heighlights">Email-Id:</span>{" "}
              <a href="mailto:info@dvrittech.co.uk"> info@dvrittech.co.uk</a>
            </li>
          </ul>
          {/* <ul className="details-list">
            <li className="list-heading">India Office</li>
            <li className="details-list-items">
              <span className="point-heighlights">Address:</span> International
              Tech Solutions Asia PVT LTD, 2-94/4 Near, N-Cnvention Khanamet,
              Madhapur, Hyderabad, Telangana - 500081
            </li>
            <li className="details-list-items">
              <span className="point-heighlights">& Office Manager:</span> Raj
              Gopal
            </li>
            <li className="details-list-items">
              <span className="point-heighlights">Phone-No:</span> +91 81069
              82884
            </li>
          </ul> */}
        </div>
        <div className="contact-form">
          <h2 className="form-heading">Get In Touch</h2>
          <form onSubmit={handleSubmit}>
            <table cellPadding={"8px"}>
              <tr>
                <td>
                  {" "}
                  <label className="inp-label" htmlFor="fullName">
                    Full Name
                  </label>
                  <InputText
                    id="fullName"
                    value={fullName}
                    pattern=".{3,100}"
                    keyfilter={/^[a-zA-Z ]*$/} 
                    onChange={(e) => {
                      setFullName(e.target.value);
                    }}
                    placeholder="Enter your full name"
                  />
                </td>
                <td>
                  <label className="inp-label" htmlFor="email">
                    Email
                  </label>
                  <InputText
                    id="email"
                    keyfilter={"email"}
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    placeholder="Enter your email"
                    type="email"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {" "}
                  <label className="inp-label" htmlFor="subject">
                    Subject
                  </label>
                  <InputText
                    id="subject"
                            pattern=".{3,100}"
                    value={subject}
                    keyfilter={/^[a-zA-Z ]*$/} 
                    onChange={(e) => {
                      setSubject(e.target.value);
                    }}
                    placeholder="Enter subject"
                  />
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  {" "}
                  <label className="inp-label" htmlFor="message">
                    Message
                  </label>
                  <InputTextarea
                    id="message"
                            pattern=".{3,100}"
                    value={message}
                    onChange={(e) => {
                      setMessage(e.target.value);
                    }}
                    rows={5}
                    placeholder="Enter your message"
                  />
                </td>
              </tr>
              <tr>
                <td>
                  {" "}
                  <Button
                  disabled={!fullName||!email||!subject||!message}
                    className="btn"
                    style={{ width: "100%" }}
                    label="Send Message"
                    icon="pi pi-send"
                    type="submit"
                    loading={loading}
                    // disabled={loading}
                  />
                </td>
              </tr>
            </table>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ContactUsPage;
