import React from "react";
import { Route, Routes } from "react-router-dom";
import { BrowserRouter as Router } from "react-router-dom";
import ContactUsPage from "../ContactUs/ContactUsPage";

import Footer from "../Comman Pages/Footer/Footer";
import Home from "../Home/Home";
import CostomSoftwareDev from "../DropdownPages/Costom Software Development/CostomSoftwareDev";
import CloudSolutions from "../DropdownPages/CloudSolutions/CloudSolutions.jsx";
import Cyberscurity from "../DropdownPages/Cybersecurity/Cyberscurity.jsx";
import DataAnylitics from "../DropdownPages/Data Analytics/DataAnylitics.jsx";
import DigitalTransformation from "../DropdownPages/Digital Transformation/DigitalTransformation.jsx";
import Career from "../Career/Career.jsx";
import About from "../About/About.jsx";

function PageRouter() {
  return (
    <Router>
      <Routes>
        <Route path="/Contact" element={<ContactUsPage />} />
      </Routes>
      <Routes>
        <Route path="/Career" element={<Career />} />
      </Routes>
      <Routes>
        <Route path="/About" element={<About />} />
      </Routes>
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      {/* Dropdown elements */}
      <Routes>
        <Route path="/CloudSolutions" element={<CloudSolutions />} />
      </Routes>
      <Routes>
        <Route
          path="/CustomSoftwareDevelopment"
          element={<CostomSoftwareDev />}
        />
      </Routes>
      <Routes>
        <Route path="/Cyberscurity" element={<Cyberscurity />} />
      </Routes>
      <Routes>
        <Route path="/DataAnylitics" element={<DataAnylitics />} />
      </Routes>
      <Routes>
        <Route
          path="/DigitalTransformation"
          element={<DigitalTransformation />}
        />
      </Routes>
      {/* Dropdown elements*/}
      <Footer />
    </Router>
  );
}

export default PageRouter;
