import "./Comman.css";
import PageRouter from "./PageRouter/PageRouter";
function App() {
  return (
    <>
      {/* <div className="Hero">
        <Navbar />
      </div> */}
      <PageRouter />
      {/* <Footer /> */}
    </>
  );
}

export default App;
